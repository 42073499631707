<template>
  <div class="flex flex-col justify-center w-full border-b border-gray-600 select-none action-group min-h-input">
    <div
      class="action-row-layer flex justify-start items-center w-full border-none py-0.5 pr-1"
      :class="bgClass"
    >
      <span class="ml-8" />

      <div class="flex justify-between w-full truncate">
        <div class="flex items-center justify-start shrink-0 min-w-0 py-2 group">
          <div class="flex items-center w-full truncate">
            <div class="text-xs truncate select-none">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CollapseRow',

  props: {

    icon: {
      type: String,
      default: ''
    },

    iconClass: {
      type: String,
      default: ''
    },
    level: {
      type: Number,
      default: 1
    },

    closed: {
      type: Boolean,
      default: true
    },

    overrideClass: {
      type: String,
      default: ''
    }
  },

  computed: {
    bgClass () {
      if (this.overrideClass !== '') return this.overrideClass
      if (this.level > 1) return 'pl-0 bg-gray-700 text-gray-200'
      return 'text-gray-200'
    }
  }

}
</script>
